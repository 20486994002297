<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-text-field dense label="Nome" v-model="currentItem.nome" :rules="requiredField" outlined />
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';

export default {
  name: "CidBaseForm",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils(
      {
        procedures: [],
        requiredField: [e => (e ? true : 'Obrigatório')]
      }

    )
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      const fields = {
        ...this.currentItem,
        ativo: Boolean(this.currentItem.ativo)
      }
      this.onSubmit(fields)
    }
  },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
  },
}
</script>
