<template>
  <div>
    <!-- Campo de Pesquisa -->
    <v-row class="mt-2">
      <v-col cols="5">
        <v-text-field dense outlined v-model="searchTerm" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details clearable
        ></v-text-field>
      </v-col>
    </v-row>
  <v-row justify="start" dense class="mt-4 ma-0 pa-0 cards-container">
    <v-col v-for="(table, index) in filteredTables" :key="index">
      <v-card class="ma-0 pa-0" @click.stop="() => dialog[table.dialog] = true">
        <v-card-text class="text-center">
          <v-row dense>
            <v-col cols="12">
              <v-icon size="52" class="blod_color">{{ table.icon }}</v-icon>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <p width="100">
                {{ table.title }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    
    <FullscreenDialog v-model="dialog.cid" >
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de CID e Descrição:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.cid = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <CID />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.specialties">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Especialidades / CBO / Associação de Procedimentos:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.specialties = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Specialties />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.councils">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Conselho de Classe:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.councils = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Councils />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.disponibilidade">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Disponibilidade:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.disponibilidade = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Disponibilidade />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.issuingOrgan">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Orgão Expedidor:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.issuingOrgan = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <IssuingOrgan />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.frequencies">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Frequências de Visitas:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.frequencies = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Frequencies />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.companyTypes">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Tipos de Empresa:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.companyTypes = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <CompanyTypes />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.documentTypes">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Tipos de Documento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.documentTypes = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <DocumentTypes />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.procedures">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Procedimentos:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.procedures = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Procedures />
        </v-card-text>
        <span style="color: red; font-size: 1.2em; ">* É <strong>obrigatório</strong> a associação dos procedimentos em <strong>especialidades!!!</strong></span>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.modalities">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Modalidades:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.modalities = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Modalities />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.motivoReprovacao">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Motivo de Reprovação de Orçamento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.motivoReprovacao = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <MotivoReprovacao/>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.motivoGlosa">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Motivo Glosa:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.motivoGlosa = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <MotivoGlosa/>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.hospital">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Hospital ou Instituição:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.hospital = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Hospital/>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.tipofornecedor">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Tipo de Fornecedor:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.tipofornecedor = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <TipoForncedor/>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.deposito">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Deposito:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.deposito = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Deposito/>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.tipoItem">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Tipo de Item:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.tipoItem = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <TipoItem/>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.tipoAutorizacao">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Tipo de Autorização:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.tipoAutorizacao = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <TipoAutorizacao/>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.motivosuspensaoatendimento">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Motivo de Suspensão dos Atendimento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.motivosuspensaoatendimento = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Motivosuspensaoatendimento/>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.addressTypes">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Tipos de Endereço:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.addressTypes = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <AddressTypes />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.zona">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Zonas de Atendimento / Residência:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.zona = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Zona />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.operatorsTable">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Código de Tabela:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.operatorsTable = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <OperatorsTable />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.operatorsDespesa">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Código de Despesa:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.operatorsDespesa = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <OperatorsDespesa />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.holidays">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Feriados:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.holidays = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Holidays />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.functions">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Funções:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.functions = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Functions />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.module">
      <v-card>
        <v-card-title class="blod_color" >
          Gerenciamento de Acesso aos Módulos (Ícones / Tela):
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.module = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Module />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.tabregister">
      <v-card>
        <v-card-title class="blod_color" >
          Gerenciamento de Acesso as Abas:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.tabregister = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <TabRegister />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.equipamento">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de equipamento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.equipamento = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Equipamento />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.esterelizavel">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de esterelizavel:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.esterelizavel = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Esterelizavel />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <FullscreenDialog v-model="dialog.taxa">
      <v-card>
        <v-card-title class="blod_color">
          Cadastro de Taxas:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.taxa = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <Taxa />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
  </v-row>
  </div>
</template>

<script>
import UtilsFunc from '../../service/utilsFunc';
import FullscreenDialog from '../FullscreenDialog.vue';
import CID from './componentes/complementary/CID/index.vue';
import Specialties from './componentes/complementary/Specialties/index.vue';
import Councils from './componentes/complementary/Council/index.vue';
import IssuingOrgan from './componentes/complementary/IssuingOrgan/index.vue';
import Frequencies from './componentes/complementary/Frequency/index.vue';
import CompanyTypes from './componentes/complementary/CompanyTypes/index.vue';
import Procedures from './componentes/complementary/Procedures/index.vue';
import Modalities from './componentes/complementary/Modalities/index.vue';
import MotivoReprovacao from './componentes/complementary/MotivoReprovacao/index.vue'
import MotivoGlosa from './componentes/complementary/MotivoGlosa/index.vue'
import AddressTypes from './componentes/complementary/AddressTypes/index.vue';
import OperatorsTable from './componentes/complementary/OperatorsTable/index.vue';
import OperatorsDespesa from './componentes/complementary/OperatorsDespesa/index.vue';
import Holidays from './componentes/complementary/Holidays/index.vue';
import Functions from './componentes/complementary/Functions/index.vue';
import Module from './componentes/complementary/Module/index.vue';
import TabRegister from './componentes/complementary/TabRegister/index.vue';
import DocumentTypes from './componentes/complementary/DocumentTypes/index.vue';
import Hospital from './componentes/complementary/Hospital/index.vue';
import TipoForncedor from './componentes/complementary/TipoFornecedor/index.vue';
import Deposito from './componentes/complementary/Deposito/index.vue';
import TipoAutorizacao from './componentes/complementary/TipoAutorizacao/index.vue';
import Motivosuspensaoatendimento from './componentes/complementary/MotivoSuspensaoAtendimento/index.vue';
import Equipamento from './componentes/complementary/Equipamento/index.vue';
import Esterelizavel from './componentes/complementary/Esterelizavel/index.vue';
import Taxa from './componentes/complementary/Taxas/index';
import Zona from './componentes/complementary/Zona/index';
import TipoItem from './componentes/complementary/TipoItem/index';
import Disponibilidade from './componentes/complementary/Disponibilidade/index';
import '../../assets/css/styles.css'

const { withCRUDUtils } = UtilsFunc

export default {
  name: 'Complementary',
  data() {
    return withCRUDUtils({
      dialog: {
        cid: false,
        specialties: false,
        councils: false,
        issuingOrgan: false,
        frequencies: false,
        companyTypes: false,
        documentTypes: false,
        procedures: false,
        modalities: false,
        addressTypes: false,
        operatorsTable: false,
        operatorsDespesa:false,
        motivoReprovacao: false,
        motivoGlosa: false,
        holidays: false,
        functions: false,
        module: false,
        tabregister: false,
        hospital:false,
        tipofornecedor:false,
        disponibilidade: false,
        deposito:false,
        motivosuspensaoatendimento: false,
        tipoAutorizacao: false,
        equipamento: false,
        esterelizavel: false,
        taxa: false,
        zona: false,
        tipoItem: false,
      },
      searchTerm: '', 
      tables: [
        { title: 'Funções', dialog: 'functions', icon: 'mdi-folder-open' },
        { title: 'Módulos', dialog: 'module', icon: 'mdi-folder-open' },
        { title: 'Abas', dialog: 'tabregister', icon: 'mdi-folder-open' },
        { title: 'Modalidades', dialog: 'modalities', icon: 'mdi-folder-open' },
        { title: 'Disponibilidade', dialog: 'disponibilidade', icon: 'mdi-folder-open' },
        { title: 'Frequência', dialog: 'frequencies', icon: 'mdi-folder-open' },
        { title: 'Código de despesas', dialog: 'operatorsDespesa', icon: 'mdi-folder-open' },
        { title: 'Código de tabelas', dialog: 'operatorsTable', icon: 'mdi-folder-open' },
        { title: 'Procedimentos', dialog: 'procedures', icon: 'mdi-folder-open' },
        { title: 'Especialidade', dialog: 'specialties', icon: 'mdi-folder-open' },
        { title: 'Conselho de classe', dialog: 'councils', icon: 'mdi-folder-open' },
        { title: 'CID - Descrição', dialog: 'cid', icon: 'mdi-folder-open' },
        { title: 'Orgão expedidor', dialog: 'issuingOrgan', icon: 'mdi-folder-open' },
        { title: 'Tipo empresa', dialog: 'companyTypes', icon: 'mdi-folder-open' },
        { title: 'Tipos de endereço', dialog: 'addressTypes', icon: 'mdi-folder-open' },
        { title: 'Tipos de documento', dialog: 'documentTypes', icon: 'mdi-folder-open' },
        { title: 'Tipos de autorização', dialog: 'tipoAutorizacao', icon: 'mdi-folder-open' },
        { title: 'Tipos de item', dialog: 'tipoItem', icon: 'mdi-folder-open' },
        { title: 'Feriados', dialog: 'holidays', icon: 'mdi-folder-open' },
        { title: 'Motivo de reprovação', dialog: 'motivoReprovacao', icon: 'mdi-folder-open' },
        { title: 'Motivo glosa', dialog: 'motivoGlosa', icon: 'mdi-folder-open' },
        { title: 'Hospitais / Instituições', dialog: 'hospital', icon: 'mdi-folder-open' },
        { title: 'Motivo de suspensão atend.', dialog: 'motivosuspensaoatendimento', icon: 'mdi-folder-open' },
        { title: 'Tipo de fornecedor', dialog: 'tipofornecedor', icon: 'mdi-folder-open' },
        { title: 'Deposito', dialog: 'deposito', icon: 'mdi-folder-open' },
        { title: 'Equipamento', dialog: 'equipamento', icon: 'mdi-folder-open' },
        { title: 'Esterelizavel', dialog: 'esterelizavel', icon: 'mdi-folder-open' },
        { title: 'Taxas', dialog: 'taxa', icon: 'mdi-folder-open' },
        { title: 'Zonas', dialog: 'zona', icon: 'mdi-folder-open' },
      ]
    })
  },
  methods: {
    normalizeString(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    },
  },
 computed: {
    filteredTables() {
      if (!this.searchTerm) return this.tables;
      const normalizedSearchTerm = this.normalizeString(this.searchTerm);
      return this.tables.filter(table =>
        this.normalizeString(table.title).includes(normalizedSearchTerm)
      );
    },
  },
  components: {
    FullscreenDialog,
    CID,
    Specialties,
    Councils,
    IssuingOrgan,
    Frequencies,
    CompanyTypes,
    DocumentTypes,
    Procedures,
    Modalities,
    AddressTypes,
    MotivoReprovacao,
    MotivoGlosa,
    OperatorsTable,
    Disponibilidade,
    OperatorsDespesa,
    Holidays,
    Functions,
    Module,
    TabRegister,
    Hospital,
    TipoForncedor,
    Deposito,
    TipoAutorizacao,
    Motivosuspensaoatendimento,
    Equipamento,
    Esterelizavel,
    Taxa,
    Zona,
    TipoItem,
  },
}
</script>

<style scoped>
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0.5rem;
}

.not-found-company {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}
.blue-icon {
  color: #396A9F;
}

</style>
