const translations = {
  "Can add": "Pode adicionar",
  "Can change": "Pode alterar",
  "Can delete": "Pode excluir",
  "Can view": "Pode visualizar",
  "entry": "registro",
  "group":"grupos"
  // ... outras traduções que você deseja
};

export function translate(string) {
  let translatedString = string;

  for (let key in translations) {
    if (translatedString.includes(key)) {
      translatedString = translatedString.replace(new RegExp(key, 'g'), translations[key]);
    }
  }
  
  return translatedString;
}

