<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-text-field dense label="Nome" v-model="currentItem.name" outlined :rules="requiredField" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field dense outlined label="Pesquisar" v-model="currentSearch"  clearable />
      </v-col>
    </v-row>
    <div class="px-3 py-2 overflow-y-auto" style="max-height: 200px;">
      <v-row v-for="permission in filteredPermissions" :key="permission.id">
        <v-checkbox class="pt-0 mt-0" multiple v-model="rolePermissions" :label="permission.name"
          :value="permission.id"></v-checkbox>
      </v-row>
    </div>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';
import api from '../../../../../http';
import { translate } from '../../../../translate';

export default {
  name: "BaseFormFunction",
  props: {
    currentItem: Object, 
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      rolePermissions: [],
      permissions: [],
      currentSearch: "",
      requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
    })
  },
  components: { ConfirmButton },
  methods: {
    async getPermissions() {
      const { data } = await api.get('/contas/permissions/');
      this.permissions = data.map(permission => {
        return {
          ...permission,
          name: translate(permission.name) 
        };
      });
    },
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      const fields = {
        ...this.currentItem,
        permissions: this.rolePermissions,
        ativo: Boolean(this.currentItem.ativo)
      }
      this.onSubmit(fields)

      this.$refs.formRef.reset()
    },
    async getRolePermission() {
      const { data } = await api.get(`/contas/groups/${this.currentItem.pk}/add_permission/`)
      this.rolePermissions = data.permissions
    }
  },
  computed: {
    filteredPermissions() {
      if (!this.currentSearch) return this.permissions
      return this.permissions.filter((permission) => permission.name.includes(this.currentSearch))
    }
  },
  mounted() {
    if (this.currentItem.pk) {
      if (this.currentItem.permissions) {
        // Se as permissões vierem como propriedades, use-as
        this.rolePermissions = this.currentItem.permissions;
      } else {
        // Senão, pegue-as do backend
        this.getRolePermission();
      }
    } else {
      this.$refs.formRef.reset();
    }
    this.getPermissions();
    this.currentItem.ativo = Number(this.currentItem.ativo);
}

}
</script>
