<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-select
        outlined
        dense
        label="Calendário"
        v-model="currentItem.calendario"
        :items="calendars"
        :item-text="getCalendarName"
        item-value="id"
        return-object
        :rules="requiredField"
        clearable
      >
      </v-select>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field dense label="Nome do Feriado" v-model="currentItem.nome" outlined :rules="requiredField" clearable />
      </v-col>
    </v-row>
    <v-row>
      <v-tooltip bottom content-class="my-tooltip-content" >
        <template v-slot:activator="{ on, attrs }">
          <Input 
            type="date" 
            label="Dia do Feriado" 
            v-model="currentItem.diaOriginal" 
            :rules="requiredField" 
            v-bind="attrs"
            v-on="on"
            clearable
          />
        </template>
        <span >Atenção no preenchimento do DIA e MÊS do Feridao.<br/> O ano será desconsiderado para que essa data vigore nos demais anos.</span>
      </v-tooltip>
    </v-row>
    <v-row dense>
      <v-textarea outlined label="Observações" v-model="currentItem.obs" clearable />
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';
import Input from '../../../../Input.vue';
import Moment from "moment"
// import VTooltip from 'v-tooltip'

export default {
  name: "HolidayBaseForm",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils(
      {
        calendars: [],
        requiredField: [v => (v && Object.keys(v).length > 0) || 'Obrigatório']
      }

    )
  },
  components: { ConfirmButton, Input },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation)
        return
      const fields = {
        ...this.currentItem,
        calendario: this.currentItem.calendario.id,
        dia: new Moment(this.currentItem.diaOriginal).locale("pt-br").format("YYYY-MM-DD"),
        ativo: Boolean(this.currentItem.ativo)
      }
      this.onSubmit(fields)
    },
    async getCalendars() {
      const LOADING_NAME = "get";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/calendarios/calendarios/");
        this.calendars = data;
        if (!this.currentItem.calendario && this.calendars.length > 0) {
      this.currentItem.calendario = this.calendars[0];
        }
      }
      catch (error) {
        this.$errorHandler(error);
        
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    // Substitui o nome que vem do banco Default por Padrão, só para melhorar a experiência do Usuário.
    getCalendarName(calendar) {
    if (calendar.nome === 'Default') {
      return 'Padrão';
    }
    return calendar.nome;
    },
  },
  mounted() {
    this.getCalendars()
    this.currentItem.ativo = Number(this.currentItem.ativo)
  },
}
</script>

<style scoped>
.my-tooltip-content {
  background-color: red !important;
  color: white !important;
  padding: 10px !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}
</style>