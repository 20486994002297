<template>
  <div>
    <v-data-table :loading="isLoading('get:companyTypes')" :items="tableData" :search="search" :headers="headers"
      :sort-by.sync="defaultSort.name" :sort-desc.sync="defaultSort.isDesc">
      <template v-slot:top>
        <v-row class="mt-4" dense>
          <v-col cols="5">
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable/>
          </v-col>
          <v-col class="text-end">
            <v-btn color="success" class="elevation-0" @click="dialog.create = true">
              <v-icon left>
                mdi-plus
              </v-icon>
              Novo
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.procedimentos`]="{ item }">
        <div>
          <span class="mr-1" v-for="(procedure, index) in item.procedimento" :key="procedure.id">
            {{ procedure.nome }}<span v-if="index < item.procedimento.length - 1">,</span>
          </span>
        </div>
      </template>
      <template v-slot:[`item.ativo`]="item">
        <v-chip class="ma-2" :color="item.item.ativo ? 'blue' : 'grey'">
          <span class="white--text">{{ item.item.ativo ? 'Ativo' : 'Inativo' }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn icon @click="onSelectRow(item, 'update')">
          <v-icon color="green">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="500">
      <v-card>
        <v-card-title  class="blod_color">
          Novo tipo de empresa
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseForm :currentItem="newItem" :loading="isLoading('post:companyTypes')" :rules="requiredField" :onSubmit="createCompanyType" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="500">
      <v-card>
        <v-card-title  class="blod_color">
          Editar tipo de empresa
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseForm :currentItem="selectedRow" :loading="isLoading('put:companyTypes')" :onSubmit="updateCompanyType" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';
import BaseForm from './BaseFormCompanyTypes.vue';
import '../../../../../assets/css/styles.css';

export default {
  name: "CompanyTypes",
  data() {
    return UtilsFunc.withCRUDUtils({
      newItem: { ativo: 1 },
      headers: [
        { text: "Editar", value: "edit", width: 50 },
        { text: "Id", value: "id", width: 90 },
        { text: "Nome", value: "nome" },
        { text: "Ativo", value: "ativo", with: 50 },
      ],
      requiredField: [e => e !== undefined && e !== null || 'Obrigatório'],
      defaultSort: {
        name: 'ativo',
        isDesc: true
      }
    });
  },
  methods: {
    async getCompanyTypes() {
      const LOADING_NAME = "get:companyTypes";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/multi_empresas/tipos/");
        this.tableData = data;
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async createCompanyType(fields) {
      const LOADING_NAME = "post:companyTypes";
      this.setLoading(LOADING_NAME);
      try {
        await api.post("/multi_empresas/tipos/", fields);
        await this.getCompanyTypes()
        this.dialog.create = false
        this.$toast.success('Tipo empresa criado com sucesso!')
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async updateCompanyType(fields) {
      const LOADING_NAME = "put:companyTypes";
      this.setLoading(LOADING_NAME);
      try {
        await api.put(`/multi_empresas/tipo/${fields.id}/`, fields);
        await this.getCompanyTypes()
        this.dialog.update = false
        this.$toast.success('Tipo empresa atualizado com sucesso!')
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
  },
  mounted() {
    this.getCompanyTypes();
  },
  components: { BaseForm }
}
</script>
