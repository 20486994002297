<template>
  <div>
    <v-data-table :loading="isLoading('get:tipoItem')" :items="tableData" :search="search" :headers="headers">
      <template v-slot:top>
        <v-row class="mt-4" dense>
          <v-col cols="5">
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
          </v-col>
          <v-col class="text-end">
            <v-btn color="success" class="elevation-0" @click="dialog.create = true">
              <v-icon left>
                mdi-plus
              </v-icon>
              Novo
            </v-btn>
          </v-col> 
        </v-row>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn icon @click="onSelectRow(item, 'update')">
          <v-icon color="green">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
          <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
        </v-chip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="500">
      <v-card>
        <v-card-title class="blod_color">
          Novo Item:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseFormTipoItem :currentItem="newItem" :loading="isLoading('post:tipoItem')" :onSubmit="createProcedure" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="500">
      <v-card>
        <v-card-title class="blod_color">
          Editar deposito:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseFormTipoItem :currentItem="selectedRow" :loading="isLoading('put:tipoItem')" :onSubmit="updateProcedure" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';
import BaseFormTipoItem from './BaseFormTipoItem.vue';
import './../../../../../assets/css/styles.css';

export default {
  name: "tipoItem",
  data() {
    return UtilsFunc.withCRUDUtils({
      newItem: { ativo:1 },
      headers: [
        { text: "Editar", value: "edit", width: 50 },
        { text: "Id", value: "id", width: 90 },
        { text: "Deposito", value: "nome" },
        { text: "Status", value: "ativo" },
      ],
      defaultSort: {
        name: 'ativo',
        isDesc: true
      }
    });
  },
  methods: {
    async getTipoItem() {
      const LOADING_NAME = "get:tipoItem";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/uteis/tipoitem/");
        data.sort((a, b) => {
          // Ordena por ativo (true vem antes de false)
          if (a.ativo !== b.ativo) {
            return a.ativo ? -1 : 1;
          }
        });
        this.tableData = data;
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async createProcedure(fields) {
      const LOADING_NAME = "post:tipoItem";
      this.setLoading(LOADING_NAME);
      try {
        await api.post("/uteis/tipoitem/", fields);
        await this.getTipoItem()
        this.dialog.create = false
        this.$toast.success('Tipo de Item criado com sucesso!')
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro.${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async updateProcedure(fields) {
      const LOADING_NAME = "put:tipoItem";
      this.setLoading(LOADING_NAME);
      try {
        await api.put(`/uteis/tipoitem/${fields.id}/`, fields);
        await this.getTipoItem()
        this.dialog.update = false
        this.$toast.success('Tipo de Item atualizado com sucesso!')
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
  },
  mounted() {
    this.getTipoItem();
  },
  components: { BaseFormTipoItem }
}
</script>
