<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-text-field dense label="Nome" v-model="currentItem.nome" outlined :rules="requiredField" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field dense label="CBO" v-model="currentItem.cbo" type="number" outlined :rules="requiredField" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete item-text="nome" item-value="id" label="Procedimento" v-model="currentItem.procedimento"
          :items="procedures" outlined dense multiple return-object :rules="requiredProcedure" ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';

export default {
  name: "CidBaseForm",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils(
      {
        procedures: [],
        requiredProcedure: [v => (v && v.length > 0) || 'Procedimento é obrigatório'],
        requiredField: [e => (e ? true : 'Obrigatório')],
      }

    )
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      const fields = {
        ...this.currentItem,
        procedimento: this.currentItem.procedimento.map(procedure => procedure.id),
        ativo: Boolean(this.currentItem.ativo)
      }
      this.onSubmit(fields)
    },
    async getProcedures() {
      const LOADING_NAME = "get";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/procedimentos/procedimentos/");
        this.procedures = data;
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Algo deu errado. Erro  ${error}`);
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    }
  },
  mounted() {
    this.getProcedures()
    this.currentItem.ativo = Number(this.currentItem.ativo)
  },
}
</script>
