<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-text-field dense label="Nome" v-model="currentItem.name" outlined :rules="requiredField" clearable />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field dense outlined label="Pesquisar" v-model="currentSearch" clearable />
      </v-col>
    </v-row>
    <div class="px-3 py-2 overflow-y-auto" style="max-height: 200px;">
      <v-row v-for="modulo in filteredModulos" :key="modulo.id">
        <v-checkbox class="pt-0 mt-0" multiple v-model="roleModulos" :label="modulo.titulo"
          :value="modulo.id"></v-checkbox>
      </v-row>
    </div>
    <!-- <v-row dense class="mt-4">
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row> -->
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';
import api from '../../../../../http';

export default {
  name: "BaseFormFunction",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      rolePermissions: [],
      roleModulos: [],
      modulos: [],
      function: [],
      currentSearch: "",
      requiredField: [e => e !== undefined || 'Obrigatório']
    })
  },
  components: { ConfirmButton },
  methods: {
    async getModulos() {
      const LOADING_NAME = "get:modulos";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/modulos/listar/");
        this.modulos = data;
      }
      catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro... Error ->  ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation)
        return
      const fields = {
        ...this.currentItem,
        modulos: this.roleModulos,
        ativo: Boolean(this.currentItem.ativo)
      }
      this.onSubmit(fields)
    },
    async getRoleModulo() {
      const { data } = await api.get(`/contas/groups/${this.currentItem.pk}/add_permission/`)
      this.roleModulos = data.modulos
    }
  },
  computed: {
    filteredModulos() {
      if (this.currentSearch === "") return this.modulos
      return this.modulos.filter((modulo) => String(modulo.titulo).toLowerCase().includes(this.currentSearch.toLowerCase()))
    }
  },
  mounted() {
    (async () => {
      await this.getModulos()
      await this.getRoleModulo();
      this.currentItem.ativo = Number(this.currentItem.ativo);
    })()
  }

}
</script>
